import { template as template_72ef38c6e6cc4f34a38388c770a9b7d4 } from "@ember/template-compiler";
const FKText = template_72ef38c6e6cc4f34a38388c770a9b7d4(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
