import { template as template_53a1d09989464b2dadd3aad412c43ae0 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_53a1d09989464b2dadd3aad412c43ae0(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
