import { template as template_a8e76efe6ad143deb5ccbb3309917f28 } from "@ember/template-compiler";
const FKLabel = template_a8e76efe6ad143deb5ccbb3309917f28(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
